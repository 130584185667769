import React from "react"

import "./questions.css"

class GeneralQuestions extends React.Component {
  constructor() {
    super()
    this.state = {
      // open: [true, true, true, true, true, true, true, true, true]
      open: [false, false, false, false, false, false, false, false, false],
    }
  }

  openMenu = ix => {
    const updated = this.state.open
    updated[ix] = !updated[ix]
    this.setState({
      open: updated,
    })
  }

  render() {
    // data: [index, header, text, special_indicator (0 - normal, 1 - languages, 2 - smartphones)]
    const data = [
      [
        0,
        "What type of organization is accesSOS?",
        "accesSOS is a non-profit 501(c)(3) organization that focuses on making emergency help accessible.",
        0,
      ],
      [
        1,
        "Why is accesSOS a nonprofit?",
        "Being a tech nonprofit allows us to build a solution that serves our community first, focusing on making a return on lives protected over profits. \n" +
          "Could you imagine if making a phone call to 911 was a for profit enterprise? Making a call for help could be limited to those who could afford it. Imagine having to listen to an ad before being able to request help, or having your data sold to the highest bidder - that's not in our values. \n" +
          "We believe that access to emergency help is a human right, and that our community members come first. We’re funded through a mixture of corporate sponsorships, grants, and donations. \n",
        0,
      ],
      [
        2,
        "Why doesn’t Text-to-911 already exist?",
        "911 was built in the 1960s using the landline system. Most of us contact 911 using our mobile phones now. In order for these systems to receive texts-to-911, Public Safety Answering Points need entirely new physical equipment. \n " +
          "As you can probably guess, this process is pretty pricey. Many municipalities and Public Safety Answering Points lack the funds and the urgency to prioritize converting their equipment. \n" +
          "The soonest we’d get access nationwide is in 10 years. But not everyone can wait that long. accesSOS exists to fill this gap.",
        0,
      ],
      [
        3,
        "How is accesSOS different from Text-to-911 and other apps?",
        "Text-to-911 works by texting back and forth with 911, but not all 911 call centers have the technology for Text-to-911. Even if they do have Text-to-911 enabled, at times they do not receive an accurate emergency location.\n" +
        "accesSOS automatically gets your geolocation from your phone and sends that to 911 call takers. In Text-to-911 enabled areas, texting back and forth with 911 can be a stressful process and formulating a sentence may be difficult. We offer a quick, icon driven menu of options so you can quickly request emergency help.",
        0,
      ],
      [
        4,
        "What languages does accesSOS work with?",
        "English, Spanish, Chinese - Simplified, Vietnamese, Arabic",
        1,
      ],
    //   [
    //     3,
    //     "What types of smartphones does accesSOS work with?",
    //     "Acer, Amazon, Apple, Asus, AT&T, Dell, Garmin, Google, HP, Lenovo, LG, " +
    //       "Microsoft, Mitsubis, Motorola, Nokia, Panasonic, Philips, Samsung, Siemens, Sony T-Mobile, Toshiba",
    //     2,
    //   ],
     
      [
        5,
        "Is accesSOS free?",
        "Yes, accesSOS will always be free to use for the public.",
        0,
      ],
      [
        6,
        "Is accesSOS secure?",
        "Yes! accesSOS takes data security seriously. \n" +
          "We also have security measures in place to prevent spam calls to 911.",

        0,
      ],
      
    //   [
    //     8,
    //     "Is accesSOS currently live and running?",
    //     "Yes! We’re live in Santa Fe, New Mexico and plan to expand to a city near you. ",
    //     0,
    //   ],
      [
        7,
        "What else is accesSOS working on?",
        "Helping 911 call centers thoroughly test Text-to-911. \n" +
          "Exploring how to integrate social services as a part of first response. \n" +
          "Spreading awareness about different types of services available for mental health needs, overpoliced communities, and marginalized communities. \n" +
          "Spreading awareness that Text-to-911 is available in their city/municipality. \n" +
          "Maintaining the technology regardless of any updates to the device’s operating system or the app itself (device agnostic).",
        0,
      ],
    ]
    return (
      <div className="container questions-container">
        <h1> General Questions (FAQ) </h1>
        {data.map((item, ix) => (
          <div className="questions-item">
            <div
              onClick={() => this.openMenu(ix)}
              className="questions-header"
              aria-hidden="true"
            >
              <h3> {item[1]} </h3>
              {this.state.open[item[0]] ? (
                <svg
                  width="19"
                  height="11"
                  viewBox="0 0 19 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.998 9.00244L9.99805 1.00143L1.99805 9.00244"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="19"
                  height="11"
                  viewBox="0 0 19 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.00195 1.00146L9.00195 9.00247L17.002 1.00146"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>

            {/*  DISPLAY CONTENT IF OPEN */}
            {/*  REGULAR  */}
            {this.state.open[item[0]] && item[3] === 0 && (
              <div className="questions-item">
                {item[2].split("\n").map(str => (
                  <p>{str}</p>
                ))}
              </div>
            )}

            {/* LANGUAGES */}
            {this.state.open[item[0]] && item[3] === 1 && (
              <div className="questions-item">
                <ul>
                {item[2].split(", ").map(str => (
                  <li>{str}</li>
                ))}
                </ul>
              </div>
            )}

            {/* SMARTPHONES */}
            {this.state.open[item[0]] && item[3] === 2 && (
              <div className="questions-item questions-smartphone">
                <ul>
                {item[2].split(", ").map(str => (
                  <li>{str}</li>
                ))}
                </ul>
              </div>
            )}

            <hr />
          </div>
        ))}
      </div>
    )
  }
}

export default GeneralQuestions
