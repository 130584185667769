import React from "react"
import Layout  from "../components/layout"

import "./thanks.css"

const Thanks = () => (
    <Layout>
        <h2 className="container thanks-container">
            Thank you for contacting us! We'll be in touch shortly.
        </h2>
    </Layout>
)

export default Thanks