import React from "react"
import "./news-card.css"
export default function NewsCard({ logo, altText, desc, link }) {
    return(
        <div className="card">
        <img src={logo} alt={altText}/>
        <h4>
            {desc}
        </h4>
        <a target="_blank" rel="noreferrer" href={link}>
            Learn More
        </a>
    </div>
    )
}