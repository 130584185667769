import React from "react"
import appDL from "../../images/appstore-download.svg"
import googleplay from "../../images/googleplay.svg"
import screen from "../../images/pwa-steps/step1_2.png"
import "./download.css"

const Download = () => (
    <div className="container downloadContainer">
        <div className="dl-imgContainer">
            <img alt='accesSOS app screen' src={screen}/>
        </div>
        <div className="downloadInfo">
            <h1>
                We contact help for you.
            </h1>
            <h2>
                Download now
            </h2>
            <a rel='noreferrer' target="_blank" href="https://apps.apple.com/us/app/accessos/id1578050242">
                <img src={appDL} alt='Download from App Store'/>
            </a>
            <a rel='noreferrer' target="_blank" href="https://play.google.com/store/apps/details?id=com.AccesSOS.AccesSOSApp&hl=en_US&gl=US">
                <img src={googleplay} alt='Download from Google Play Store'/>
            </a>
        </div>
        
    </div>
)

export default Download;