/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // const [sidebarToggle, setSidebarToggle] = useState(false)

  // function toggler() {
  //   setSidebarToggle(!sidebarToggle)
  //   console.log(sidebarToggle)
  // }

  const renderChildren = () => {
    return React.Children.map(children, child => {
      return React.cloneElement(child, {
        // sideToggle: this.props.sideState,
        // sideToggler: this.props.setSideState,
      })
    })
  }

  //sidebarToggler={this.props.setSideState}

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="layout-mainContainer">
        <div>
          <main>{renderChildren()}</main>
          {/* donate button */}
          <div className="donate-fab"
           
          >
            <a
              href="https://donorbox.org/911textapp-nationwide"
              target="_blank"
              rel="noreferrer"
            >
              {/* <button className="desktop-donate">Donate</button> */}
              <div
                style={{
                  borderRadius: "50%",
                  width: "55px",
                  height: "55px",
                  border: "3px white solid",
                  backgroundColor: "#de5464",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <VolunteerActivismIcon style={{ color: "white" }} />
              </div>
            </a>
          </div>
        </div>

        <footer className="layout-footer" />
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
