import React from "react"
import Layout from "../components/layout"
// import GetInvolved from "../page_components/home/get-involved"

// var Iframe = React.createClass({
//   render: function () {
//     return (
//       <div>
//         <iframe
//           src={"https://www.w3schools.com/tags/tag_iframe.ASP"}
//           height={400}
//           width={300}
//         />
//       </div>
//     )
//   },
// })

const Careers = () => (
  <Layout>
    {/* <Iframe /> */}
    {/* <iframe
      src="https://notioniframe.com/notion/1ec42avlechl"
      style={{ width: "100%", height: "100vh", border: "0", padding: "0" }}
    ></iframe> */}
    {/* <iframe
      src="https://www.notion.so/accessos/Job-Board-external-4cf4579f919a42228d1d6818b196dadf"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      width="100%"
      height="100%"
      scrolling="auto"
    ></iframe>
    <iframe
      src="https://en.wikipedia.org/wiki/HTML_element#Frames"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      width="100%"
      height="100%"
      scrolling="auto"
    ></iframe> */}
    {/* <GetInvolved /> */}
  </Layout>
)

export default Careers
