import React from "react"
import gabriella from "../../images/gabriella.png"
import "./our-story.css"

const OurStory = () => (
  <div className="home-page-section container-constrain" id="our-story">
    <h2 className="title-font">Our story</h2>
    <div className="story-blurb">
      <img src={gabriella} alt="access S O S founder, Gabriella Wong"/>
      <div>
        <p className="body-text">
          “Both of my parents are deaf, so we communicate using American Sign Language. When my father had a gallbladder rupture he was all 
          alone and I didn't see his texts for help in time. During one of the most vulnerable, desperate moments of his life, he couldn't 
          contact 911 to get help. He almost died because of this inequity. These personal experiences motivate me to keep doing the hard work 
          of fixing this problem.”
        </p>
        <label>Gabriella Wong, Founder</label>
      </div>
    </div> 
    <div className="iframe-wrapper">
      <div>
        <iframe
          src="https://www.youtube.com/embed/XZjEDn0tjPw" 
          title="The App Making 911 More Accessible for the Deaf Community"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen/>
      </div>
    </div>
  </div>
)

export default OurStory
