import React from "react"
import Layout from "../components/layout"
import Resources from "../page_components/faq/resources"
import GetInvolved from "../page_components/home/get-involved"
const Resources_page = () => (
    <Layout>
        <Resources />
        
        <GetInvolved />

    </Layout>
)

export default Resources_page