import React from "react"
import mockupImg from "../../images/mockup.png"
import featForbes from "../../images/featured-forbesLogo.svg"
import featSXSW from "../../images/featured-SXSWLogo.svg"
import featNowThis from "../../images/featured-NowThisLogo.svg"
import featabc7 from "../../images/featured-abc7Logo.svg"
import featnbc from "../../images/featured-nbcLogo.svg"
import appDL from "../../images/appDL.svg"
import googleplay from "../../images/googleplay.svg"
import quotes from "../../images/illustrations/quotes.svg"

// import headerPhone from "../../images/illustrations/header-phone.svg"
import headerPhone from "../../images/illustrations/new-header-phone.png"
import signLanguageIcon from "../../images/illustrations/sign-language-icon.svg"
import speakingIcon from "../../images/illustrations/speaking-icon.svg"
import languageIcon from "../../images/illustrations/language-icon.svg"
import muteIcon from "../../images/illustrations/mute-icon.svg"
import phoneMockup from "../../images/illustrations/phoneMockUpDownload.svg"
import userIcon from "../../images/illustrations/user-icon.svg"
import heartbeatIcon from "../../images/illustrations/heartbeat-icon.svg"
import micIcon from "../../images/illustrations/mic-icon.svg"
import portrait from "../../images/portrait.png"

import step1 from "../../images/illustrations/download-step1.svg"
import step2 from "../../images/illustrations/select-step2.svg"
import step3 from "../../images/illustrations/detect-step3.svg"
import step4 from "../../images/illustrations/connect-step4.svg"
import step5 from "../../images/illustrations/dispatch-step5.svg"
import OurStory from "./our-story"
import SubmissionForm from "../../components/submissionform"

import "./home.css"

// import ContactSteps from "./contact-steps"

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      submitted: false,
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this) /* FOR EMAIL CHECK */
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleSubmit = event => {
    if (!this.state.email.match(/([a-zA-Z0-9.\-_]+)@([a-z]+).([a-z]+)/)) {
      this.setState({
        invalid: true,
        submitted: false,
      })
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": "Subscribed", ...this.state }),
      })
        // .then(() => alert("Success!"))
        .catch(error => alert(error))

      // event.preventDefault();
      this.setState({
        email: "",
        submitted: true,
        invalid: false,
      })
    }
    event.preventDefault()
  }

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <div className="page-container">
        {/* MAIN HEADER SECTION */}
        <div className="header-wrapper">
          <div className="home-container container-constrain">
            <img className="phone-graphic"
              src={headerPhone} alt="access SOS app displayed on a phone"/>
            <div style={{display:"flex", alignContent:"center"}}>
               <h1 style={{letterSpacing:"0.65px"}}>
                Emergency communication <br/>for <span style={{color:"#DF4154"}}>everyone</span>
              </h1>
            </div>
            <div className="header-icon-list">
              <div>
                <img  className="heroIcons" src={signLanguageIcon} alt="hand gesture for American Sign Language (A S L)"/>
                <h3>
                  Deaf and Hard of Hearing
                </h3>
              </div>
              <div className="header-icon-item">
                <img className="heroIcons" src={speakingIcon} alt="svg illustration of a person speaking"/>
                <h3>
                  Non-Speaking Individuals
                </h3>
              </div>
              <div className="header-icon-item">
                <img  className="heroIcons" src={languageIcon} alt="svg illustration of a chinese alphabetical character behind the letter A"/>
                <h3>
                  People with Limited English
                </h3>
              </div>
              <div className="header-icon-item">
                <img  className="heroIcons" src={muteIcon} alt="svg illustration of a muted volume symbol"/>
                <h3>
                  When speaking is dangerous
                </h3>
              </div>
            </div>
            <div className="phone-graphic-mobile">
            
            <img style={{width: "60%", transform: "rotate(3deg)"}}
              src={headerPhone}
              alt="access SOS app displayed on a phone"
            />
          </div>
            <h3 style={{fontSize:20}}>Donate today to help us expand our reach and protect more lives.</h3>
            <div className="button-group">
              <a className="donate-button" href="https://donorbox.org/911textapp-nationwide" rel="noreferrer" target="_blank">
                Donate now
              </a>
           <a
              className="download-app-button"
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default behavior of the anchor tag
                const targetElement = document.querySelector('.download-wrapper'); // Replace '.target-classname' with the actual class you want to scroll to
                if (targetElement) {
                  targetElement.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              Download app
            </a>
            </div>
          </div>
        </div> 

        {/* APP DOWNLOAD SECTION*/}
        <div className="download-wrapper">
          <h2 className="title-font" style={{marginTop:"20px"}}>
            Don't wait, download our free app
          </h2>
          <div className="description-wrapper container-constrain">
            <img src={phoneMockup} alt="access SOS app displayed on a phone with a qr code to download the app next to it"/>
            <div className="description-div">
              <h3 className="sub-header">Available in California and New Mexico!</h3>
              <p className="body-text"> 
                Connect to 911 or mental health when text-to-911 or phone calls are not possible.
                <br/><br/>
                Our free app features a user-friendly, icon-based interface that's intuitive for everyone, no matter their
                language skills. With just a few taps, you can quickly and discreetly alert emergency services to your 
                situation. 
                <br/><br/>
                {/* <button>
                  How it works
                </button> */}
                <a style={{color:"#FFFFFF"}} href="/how-it-works">Learn how it works {'>'}</a>
              </p>
              <div className="download-buttons">
                <a href="https://play.google.com/store/apps/details?id=com.AccesSOS.AccesSOSApp" rel="noreferrer" target="_blank">
                  <img src={googleplay} alt="Google Play download button"/>
                </a>
                <a href="https://apps.apple.com/us/app/accessos/id1578050242" rel="noreferrer" target="_blank">
                  <img src={appDL} alt="Apple App Store download button"/>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* MISSION STATEMENT SECTION */}
        <div className="home-page-section container-constrain">
          <h2 className="title-font">We are a nonprofit on a mission to make 911 accessible for all</h2>
          <div className="details-block">
            <img src={portrait} alt="A woman using the access SOS app on her phone."/>
            <div className="mission-details">
                <h3 className="sub-header" style={{marginBottom:"10px"}}>
                  911 is voice- and English-centric
                </h3>
                <p className="body-text" style={{marginTop: "0px", marginBottom:"0px", lineHeight: "normal"}}>
                  37 million Americans cannot call 911 because they are Deaf or Hard of Hearing. 29.6 million of people in the U.S. have limited English proficiency. Many more people are unable to use their voice in a dangerous situation like domestic violence.
                  <br></br>
                  All of them are left out. 
                </p>
                <h3 className="sub-header" style={{marginTop: "15px", marginBottom:"0px"}}>
                  Our mission
                </h3>
                <p className="body-text" style={{marginTop: "10px", marginBottom:"10px", lineHeight: "normal"}}>
                  We want to make sure that everyone can quickly contact emergency services through our 24/7, free mobile app.
                </p>
            </div>
          </div>
          
        </div>

        {/* CONTACT STEPS SECTION */}
        <div className="home-page-section container-constrain">
          <h2 className="title-font">
            We help you contact emergency services without the need to speak
          </h2>
          <ol className="custom-list">
            <li>
              <img alt='' src={step1}/>
              <h3>Download</h3>
              <p>
                our app from&nbsp;
                <a href="https://play.google.com/store/apps/details?id=com.AccesSOS.AccesSOSApp&hl=en_US&gl=US" target="_blank" rel="noreferrer">
                  Google Play 
                </a>
                &nbsp;or&nbsp;
                <a href="https://apps.apple.com/us/app/accessos/id1578050242" target="_blank" rel="noreferrer">
                  App Store
                </a>
              </p>
            </li>
            <li>
              <img alt='' src={step3}/>
              <h3>Detect</h3>
              <p>our app detects your exact location</p>
            </li>
            <li>
              <img alt='' src={step2}/>
              <h3>Select</h3>
              <p>whether you need Medical, Police, Fire or Mental Health services</p>
            </li>
            <li>
                <img alt='' src={step4}/>
                <h3>Connect</h3>
                <p>with 911 dispatchers in the chat</p>
            </li>
            <li>
                <img alt='' src={step5}/>
                <h3>Dispatch</h3>
                <p>Stay calm and wait for help</p>
            </li>
          </ol>
        </div>

        {/* EMAIL/ZIP SIGN UP SECTION */}
        <div className="submission-wrapper home-page-section">
          <div className="submission-section container-constrain">
            <h3 className="title-font">
              Want accesSOS in your area?
            </h3>
            <div className="submission-forms">
              <SubmissionForm
                single={true}
                title="Sign up for updates about our product launch across the U.S."
                formName="Subscribed"
                buttonText="Sign up"
                keys={[
                  {
                    key: 'email',
                    placeholder: 'example@email.com',
                    required: true,
                    needsVerification: true,
                    regexCheck: /([a-zA-Z0-9.\-_]+)@([a-z]+).([a-z]+)/,
                    adjustable: false
                  }
                ]}
                stateMap={{
                  "formName": "Subscribed",
                  "email": "",
                  "failMessage":"",
                  "submitted":false
                }}
              />
              <SubmissionForm
                single={true}
                title="Enter your zip code so that we know where to launch next!"
                formName="zipcode"
                buttonText="Send"
                keys={[
                  {
                    key: 'zipcode',
                    placeholder: 'Enter your Zip',
                    required: true,
                    needsVerification: true,
                    regexCheck: /^\d{5}(-\d{4})?$/,
                    adjustable: false
                  }
                ]}
                stateMap={{
                  "formName": "zipcode",
                  "zipcode": "",
                  "failMessage":"",
                  "submitted":false
                }}
              />
            </div>
          </div>
        </div>

        <OurStory/>

        <div className="numbers-section">
          <h2 className="title-font" style={{ marginBottom:"0.3rem"}}>Our work in numbers</h2>
          <div style={{color:"#FFFFFF", textAlign:"center", marginBottom:"3rem"}}>
            <p className="body-text">As of 31 December, 2024</p>
          </div>
          <ul>
            <li>
              <img src={userIcon} alt="phone with a checkmark overlayed"/>
              <h3 className="title-font"> 34,333 </h3>
              <p className="body-text"> people utilized the accesSOS platform </p>
            </li>
            <li>
              <img src={heartbeatIcon} alt="a heart with a heartbeat overlayed"/>
              <h3 className="title-font"> 120+ </h3>
              <p className="body-text"> people accessed lifesaving help using our app </p>
            </li>
            <li>
              <img src={micIcon} alt="a microphone icon"/>
              <h3 className="title-font"> 7,282,155 </h3>
              <p className="body-text"> people reached through advocacy and awareness campaigns </p>
            </li>
          </ul>

        </div>

        <div className="testimonial-wrapper">
          <h2 className="title-font"> From accesSOS users </h2>
          <div className="testimonial-carousel container-constrain">
            <ul>
              <li>
                <img src={quotes} alt="leftmost quotation mark"/>
                <p className="body-text">
                  accesSOS benefits everyone. I've been in situations when 
                  I was hiding from an intruder. Even whispering was too 
                  loud to get help.
                </p>
                <label className="body-text"> Cara, New Mexico </label>
              </li>
              <li>
                <img src={quotes} alt="leftmost quotation mark"/>
                <p className="body-text">
                  accesSOS is life changing for my sister-in-law because she 
                  is non-verbal and accesSOS gives her a voice in an emergency.
                </p>
                <label className="body-text"> Elena, San Francisco </label>
              </li>
              <li>
                <img src={quotes} alt="leftmost quotation mark"/>
                <p className="body-text">
                  accesSOS is very easy to use. Pictures describing the type of
                  the emergency, are very clear and self explanatory.
                </p>
                <label className="body-text"> Ian, Berkeley </label>
              </li>
            </ul>  
          </div>
        </div>  
        {/* TODO: improve mobile view - maybe flex-direction column or swipeable carousel */}
        {/* <div>
          <div className="testimonial-block">
            <div className="testimonial-title">
              <h3 className="title-font">From accesSOS <span style={{color: "#DF4154"}}>Users</span></h3>
            </div>
            <div className="testimonial-section">
              <div className="testimonial-box">
                <img className="quotes" src={quotes} alt="Testimonials from accesSOS Users" />
                <div className="testimonial">
                  <h3>accesSOS benefits everyone. I've been in situations when I was hiding from an intruder. Even whispering was too loud to get help.</h3>
                  <h3 className="testimonial-source">Cara, New Mexico</h3>
                </div>
              </div>
              <div className="testimonial-box">
                <img className="quotes" src={quotes} alt=''/>
                <div className="testimonial">
                  <h3>accesSOS is life changing for my sister-in-law because she is non-verbal and accesSOS gives her a voice in an emergency.</h3>
                  <h3 className="testimonial-source">Elena, San Francisco</h3>
                </div>
              </div>
              <div className="testimonial-box">
                <img className="quotes" src={quotes} alt=''/>
                <div className="testimonial">
                  <h3>accesSOS is very easy to use. Pictures describing the type of the emergency, are very clear and self-explanatory.</h3>
                  <h3 className="testimonial-source">Ian, Berkeley</h3>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        
      </div>
    )
  }
}

export default HomePage
