import React from "react"

import "./subscribe-pop-up.css"

class SubscribePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            submitted: false,
            invalid: false,
        }
        this.handleChange = this.handleChange.bind(this) /* FOR EMAIL CHECK */
    }

    /* Prevent scrolling when component displayed */
    componentDidMount() {
        document.body.parentElement.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.parentElement.style.overflow = "";
    }

    encode = (data) => {
        return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
    }
    
    handleSubmit = (event) => {
        if (!this.state.email.match(/([a-zA-Z0-9.\-_]+)@([a-z]+).([a-z]+)/)) {
            this.setState({ 
                invalid: true,
                submitted: false,
            });
        } else {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: this.encode({ "form-name": "Subscribed", ...this.state })
            })
                // .then(() => alert("Success!"))
                .catch(error => alert(error));

            // event.preventDefault();
            this.setState({
                email: "",
                submitted: true,
                invalid: false,
            })
        }
        event.preventDefault();
    };

    handleChange = (event) => this.setState({ [event.target.name]: event.target.value });

    render() {
        return (
            <div className="popup-background">
                <div className="popup-container">
                    <svg className="popup-close" onClick={this.props.closePopUp} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 2L2 18" stroke="#4B4B4B" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 2L18 18" stroke="#4B4B4B" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <div className="popup-contentContainer">
                        <h1> Newsletter </h1>
                        <h2> We are currently testing in limited areas. We’ll notify you once we’re available where you live </h2>
                        <form onSubmit={this.handleSubmit} name="Subscribed" data-netlify="true" data-netlify-honeypot="bot-field" netlify="true">
                            <div className="popup-subscribeContainer"> 
                                {/* <input type="email" id="email" name="email" onChange={this.handleChange} placeholder="example@email.com" value={this.state.email} required/> */}
                                <input type="text" id="email" name="email" onChange={this.handleChange} placeholder="example@email.com" value={this.state.email} required/>
                                <button onClick={this.submitForm} className="button"> Subscribe </button>
                            </div>
                        </form>
                        {this.state.submitted && (
                            <div className="popup-submitSuccess"> 
                                Thank you for subscribing!
                            </div>
                        )}
                        {this.state.invalid && (
                            <div className="popup-submitFail">
                                Enter a valid email address
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}


export default SubscribePopUp