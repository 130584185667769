import React from "react"
import Layout from "../components/layout"
import GeneralQuestions from "../page_components/faq/questions"
import Resources from "../page_components/faq/resources"
import GetInvolved from "../page_components/home/get-involved"
const FAQ = () => (
    <Layout>
        <GeneralQuestions />
        <Resources/>
        <GetInvolved />
    </Layout>
)

export default FAQ