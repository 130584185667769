
import React from "react"
import { Link } from "gatsby"
import Layout  from "../components/layout"
import { navigate } from "gatsby"
import "./contact.css"

class ContactPage extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            comments: "",
        }
    }

    encode = (data) => {
        return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
    }
    
    handleSubmit = (event) => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: this.encode({ "form-name": "Partner-Form", ...this.state })
        })
            .then(() => navigate("/thanks"))
            .catch(error => alert(error));

        event.preventDefault();
        this.setState({
            name: "",
            email: "",
            comments: "",
        })
    };

    handleChange = (event) => this.setState({ [event.target.name]: event.target.value });
    
    render() {
        const { name, email, comments } = this.state;
        return (
            <Layout>
                <div className="container contact-container">
                    <h1> Get In Touch </h1>
                    <form className="contact-form" onSubmit={this.handleSubmit} name="Partner-Form" data-netlify="true" data-netlify-honeypot="bot-field" netlify="true">
                        <input type="hidden" name="form-name" value="Partner-Form" />
                        <p>
                            <label htmlFor="name"> Full Name </label> <span className="contact-red"> * </span>
                            <input type="text" id="name" name="name" onChange={this.handleChange} value={name} required/>
                        </p>
                        <p>
                            <label htmlFor="email"> Email Address </label> <span className="contact-red"> * </span>
                            <input type="email" id="email" name="email" onChange={this.handleChange} value={email} required/>
                        </p>
                        <p>
                            <label htmlFor="comments"> Comments or questions? </label>
                            <textarea id="comments" name="comments" onChange={this.handleChange} value={comments} />
                        </p>
                        <p>
                            <button className="button contact-submitButton" onClick={this.submitForm}> 
                                Submit
                            </button>
                        </p>
                    </form>

                    <div className="contact-detailsText">
                        <p> If you would like to partner or can help out in any way, please go to our <Link to="/partner" className="contact-detailsLink"> partner </Link> page </p>
                        <hr/>

                        <p className="contact-detailsTitle"> Email Address </p>
                        <br/>
                        <p> Email us at <a href="mailto:hello@accessos.io?Subject=Text911 - Let's Collaborate!" className="contact-detailsLink"> hello@accessos.io </a> </p>
                        <hr/>

                        <p className="contact-detailsTitle"> Mailing address </p>
                        <br/>
                        <p> 1012 Torney Avenue </p>
                        <p> San Francisco, CA 94129 </p>
                        <hr/>
                    </div>
                </div>
             
            </Layout>
        )
    }
}

export default ContactPage
