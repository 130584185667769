
import React from "react"
import Layout  from "../components/layout"
import { navigate } from "gatsby"
import "./partner.css"

class PartnerPage extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            organization: "",
            comments: "",
        }
    }

    encode = (data) => {
        return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
    }
    
    handleSubmit = (event) => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: this.encode({ "form-name": "Partner-Form", ...this.state })
        })
            .then(() => navigate("/thanks"))
            .catch(error => alert(error));

        event.preventDefault();
        this.setState({
            name: "",
            email: "",
            organization: "",
            comments: "",
        })
    };

    handleChange = (event) => this.setState({ [event.target.name]: event.target.value });
    
    render() {
        const { name, email, organization, comments } = this.state;
        return (
            <Layout>
                <div className="container partner-container">
                    <h1> Partner With Us </h1>
                    <form className="partner-form" onSubmit={this.handleSubmit} name="Partner-Form" data-netlify="true" data-netlify-honeypot="bot-field" netlify="true">
                        <input type="hidden" name="form-name" value="Partner-Form" />
                        <p>
                            <label htmlFor="name"> Full Name </label> <span className="partner-red"> * </span>
                            <input type="text" id="name" name="name" onChange={this.handleChange} value={name} required/>
                        </p>
                        <p>
                            <label htmlFor="email"> Email Address </label> <span className="partner-red"> * </span>
                            <input type="email" id="email" name="email" onChange={this.handleChange} value={email} required/>
                        </p>
                        <p>
                            <label htmlFor="email"> Name of Organization (Optional) </label>
                            <input type="text" id="organization" name="organization" onChange={this.handleChange} value={organization} />
                        </p>
                        <p>
                            <label htmlFor="comments"> Comments or questions? </label>
                            <textarea id="comments" name="comments" onChange={this.handleChange} value={comments} />
                        </p>
                        <p>
                            <button className="button partner-submitButton" onClick={this.submitForm}> 
                                Submit
                            </button>
                        </p>
                    </form>
                </div>
            </Layout>
        )
    }
}

export default PartnerPage
