import React from "react"
// import StudentDash from "../../public/Debateclub119a449765604e239c48b58c7ff9521b.html"

export default function Notion() {
  return (
    <div>
      {/* <iframe src={StudentDash}></iframe> */}
    </div>
  )
}
