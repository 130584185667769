import React from "react"
import { navigate } from "gatsby"

class SubmissionForm extends React.Component {
  map = new Map()
  constructor(props) {
    super(props)
    for (let i = 0; i < props.keys.length; i++) {
      this.map.set(props.keys[i].key, {
        needsVerification: props.keys[i].needsVerification,
        regexCheck: props.keys[i].regexCheck,
      })
    }
    this.state = props.stateMap
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleSubmit = event => {
    let children = Array.from(event.target.firstChild.children)
    let failedKeys = []
    for (let i = 0; i < children.length; i++) {
      if (
        children[i].tagName === "INPUT" ||
        children[i].tagName === "TEXTAREA"
      ) {
        let name = children[i].attributes.name.value
        let value = children[i].attributes.value.value
        let check = this.map.get(name)
        if (check !== undefined) {
          if (check.needsVerification && !value.match(check.regexCheck)) {
            failedKeys.push(name)
          }
        }
      }
    }
    if (failedKeys.length === 0) {
      let formName = this.props.formName
      this.setState({ failMessage: "" })
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": formName, ...this.state}),
      })
        .then(() => {
          this.setState({ submitted: true, zipcode: "", email: "" })
        })
        .catch(error => alert(error))
      for (let i = 0; i < this.props.keys; i++) {
        let key = this.props.keys[i].key
        this.setState({ [key]: "" })
      }
    } else {
      let msg = `Please enter a valid submission for ${failedKeys[0]}`
      for (let i = 1; i < failedKeys.length; i++) {
        msg += `, ${failedKeys[i]}`
      }
      failedKeys.length > 1 ? (msg += " fields") : (msg += " field")
      this.setState({ failMessage: msg })
    }
    // fetch("/", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //     body: this.encode({ "form-name": "Partner-Form", ...this.state })
    // })
    //     .then(() => navigate("/thanks"))
    //     .catch(error => alert(error));
    event.preventDefault()
  }

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <div className="form-div">
        {this.props.single ? (
          <div>
            <h3 className="sub-header"> {this.props.title} </h3>
            <form
              onSubmit={this.handleSubmit}
              name={this.props.formName}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              netlify="true"
            >
              <div className="footer-subscribeContainer">
                {/* <input type="email" id="email" name="email" onChange={this.handleChange} placeholder="example@email.com" value={this.state.email} required/> */}
                {this.props.keys.map(formItem => {
                  if (!formItem.adjustable) {
                    return (
                      <input
                        type="text"
                        id={formItem.key}
                        name={formItem.key}
                        key={formItem.key}
                        onChange={this.handleChange}
                        placeholder={formItem.placeholder}
                        value={this.state[formItem.key]}
                        required={formItem.required}
                      />
                    )
                  } else {
                    return (
                      <textarea
                        type="text"
                        id={formItem.key}
                        name={formItem.key}
                        key={formItem.key}
                        onChange={this.handleChange}
                        placeholder={formItem.placeholder}
                        value={this.state[formItem.key]}
                        required={formItem.required}
                      />
                    )
                  }
                })}
                <button onClick={this.submitForm} className="button">
                  {this.props.buttonText}
                </button>
              </div>
            </form>
            {this.state["failMessage"] !== undefined && (
              <div className="footer-submitFail">
                {this.state["failMessage"]}
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
        {this.state.submitted && this.state.formName == "zipcode" && (
          <div style={{ color: "white", textAlign: "center" }}>
            Thank you for submitting!
          </div>
        )}
        {this.state.submitted && this.state.formName == "Subscribed" && (
          <div style={{ color: "white", textAlign: "center" }}>
            Thank you for subscribing!
          </div>
        )}
      </div>
    )
  }
}

export default SubmissionForm
