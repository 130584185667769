import React from "react"

import "./resources.css"

const Resources = () => (
  <div className="container resources-container">
    <h1> Resources </h1>

    <div className="resources-item">
      <h3>
        Where can I find more information about Text-to-911?
      </h3>

      <a target="_blank" rel="noreferrer" href="https://www.fcc.gov/consumers/guides/what-you-need-know-about-text-911">
        Quick facts and FAQs by the FCC
      </a>
      <hr/>
    </div>
    <div className="resources-item">
      <h3>
        Where is Text-to-911 Available?
      </h3>

      <a target="_blank" rel="noreferrer" href="https://www.fcc.gov/general/psap-text-911-readiness-and-certification-form">
        Registry of areas by the FCC
      </a>
      <hr/>
    </div>
    <div className="resources-item">
      <h3>
        Can I see a map of areas with Text-to-911 supported?
      </h3>

      <a target="_blank" rel="noreferrer" href="https://www.text911.info">
        Map by AccesSOS
      </a>
      <hr/>
    </div>
  </div>
)

export default Resources
