import React from "react"
import { Link } from "gatsby"

import Drawer from "@mui/material/Drawer"
import Button from "@mui/material/Button"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import { MenuSharp } from "@mui/icons-material"
import MenuRoundedIcon from "@mui/icons-material/MenuRounded"
import InfoIcon from "@mui/icons-material/Info"
import HelpCenterIcon from "@mui/icons-material/HelpCenter"
import WorkIcon from "@mui/icons-material/Work"
import GroupsIcon from "@mui/icons-material/Groups"

import HandshakeIcon from "@mui/icons-material/Handshake"
import PublicIcon from "@mui/icons-material/Public"
import ContactSupportIcon from "@mui/icons-material/ContactSupport"
import NewspaperIcon from "@mui/icons-material/Newspaper"

import { createTheme, ThemeProvider } from "@mui/material"

import Typography from "@mui/material/Typography"
import accesSOS_logo_transparent_cropped from "../images/accesSOS_logo_transparent_cropped.png"

import advocacy from "../images/illustrations/navbar/advocacy.svg"
import contact from "../images/illustrations/navbar/contact-us.svg"
import faq from "../images/illustrations/navbar/faq.svg"
import news from "../images/illustrations/navbar/in-the-news.svg"
import meet from "../images/illustrations/navbar/meet-the-team.svg"
import join from "../images/illustrations/navbar/join-the-team.svg"
import partnership from "../images/illustrations/navbar/partnership.svg"
import resources from "../images/illustrations/navbar/resources.svg"

import "./desktopnav.css"
import "../fonts/NunitoSansImport.css"

export default function DesktopNav() {
  const [open, setOpen] = React.useState(false)

  const theme = createTheme({
    typography: {
      fontFamily: ["Nunito Sans", "sans-serif"].join(","),
    },
  })

  const sidebarLinkElement = {
    textDecoration: "none",
    color: "black",
    marginTop: 15,
  }

  const typographyWeight = 700

  const sidebarMaterial = () => {
    return (
      <ThemeProvider theme={theme}>
        <div className="sidebar">
          <img alt="acesSOS Logo" src={accesSOS_logo_transparent_cropped} className="logo"/>
          <Link to="/resources_page" className="sidebar-link">
            <ListItem key={0} onClick={() => setOpen(false)}>
              <img src={resources}/>
              <Typography
                fontWeight={typographyWeight}
                className="typography-style"
              >
                Resources
              </Typography>
            </ListItem>
          </Link>
          <Link to="/faq" className="sidebar-link">
            <ListItem key={1} onClick={() => setOpen(false)}>
              <img src={faq}/>
              <Typography
                fontWeight={typographyWeight}
                className="typography-style"
              >
                FAQ
              </Typography>
            </ListItem>
          </Link>
          <Link
            to="https://accessos.notion.site/Team-accesSOS-Public-Directory-68a3d83542d14bbbafc6d156a665c86b"
            className="sidebar-link"
          >
            <ListItem key={2} onClick={() => setOpen(false)}>
              <img src={meet}/>
              <Typography
                fontWeight={typographyWeight}
                className="typography-style"
              >
                Meet the Team
              </Typography>
            </ListItem>
          </Link>
          <Link
            to="https://accessos.notion.site/Job-Board-external-4cf4579f919a42228d1d6818b196dadf"
            className="sidebar-link"
          >
            <ListItem key={1} onClick={() => setOpen(false)}>
              <img src={join}/>
              <Typography
                fontWeight={typographyWeight}
                className="typography-style"
              >
                Join Us
              </Typography>
            </ListItem>
          </Link>

          <Link to="/partner" className="sidebar-link">
            <ListItem key={0} onClick={() => setOpen(false)}>
              <img src={partnership}/>
              <Typography
                fontWeight={typographyWeight}
                className="typography-style"
              >
                Partner
              </Typography>
            </ListItem>
          </Link>
          <Link
            to="https://www.facebook.com/groups/access911"
            className="sidebar-link"
          >
            <ListItem key={0} onClick={() => setOpen(false)}>
              <img src={advocacy}/>
              <Typography
                fontWeight={typographyWeight}
                className="typography-style"
              >
                Advocacy
              </Typography>
            </ListItem>
          </Link>
          <Link to="/contact" className="sidebar-link">
            <ListItem key={0} onClick={() => setOpen(false)}>
              <img src={contact}/>
              <Typography
                fontWeight={typographyWeight}
                className="typography-style"
              >
                Contact Us
              </Typography>
            </ListItem>
          </Link>
          <Link to="/press" className="sidebar-link">
            <ListItem key={0} onClick={() => setOpen(false)}>
              <img src={news}/>
              <Typography
                fontWeight={typographyWeight}
                className="typography-style"
              >
                In the News
              </Typography>
            </ListItem>
          </Link>
        </div>
      </ThemeProvider>

      // <Link to="/resources_page">
    )
  }

  const getList = () => (
    <div style={{ width: 250 }}>
      {/* onClick={() => setOpen(false)} */}
      {sidebarMaterial()}
    </div>
  )

  const toggle = toggled => {
    setOpen(!open)

    console.log("clciked")

    // /* Prevent scrolling when menu open */
    // if (toggled) {
    //   document.body.parentElement.style.overflow = "hidden"
    // } else {
    //   document.body.parentElement.style.overflow = ""
    // }
  }

  const closeMenu = () => {
    setOpen(false)
    // document.body.parentElement.style.overflow = "";
  }

  return (
    <div className="desktop-container">
      <div style={{ display: "flex" }}>
        <div>
          <Button onClick={() => setOpen(true)}>
            <MenuSharp fontSize="large" sx={{ color: "#DF4154" }} />
          </Button>
          <Drawer open={open} anchor={"left"} onClose={() => setOpen(false)}>
            {sidebarMaterial()}
          </Drawer>
        </div>
      </div>
    </div>
  )
}
