import React from "react"
// import { Link } from "gatsby"

const Embed = () => (
  <div>
    {/* <iframe
      width="100%"
      height="900px"
      src="https://docs.google.com/document/d/e/2PACX-1vQI90J2gak8HVXihlO7kZe2BFXAnCkwwLy79A4_txw9JNeAPEnQRXHRqk4sYwS2lBPmf2IJ_w9lMSiU/pub?embedded=true"
    ></iframe> */}
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSc09SqnXvMMBAlYT0DvCrca1Ruoz43rwLvCKs2ksQA3G3VsMw/viewform?embedded=true"
      width="640"
      height="582"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Test Form"
    >
      Loading…
    </iframe>
  </div>
)

export default Embed
