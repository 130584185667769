import * as React from "react"

import Layout from "../components/layout"
import SubscribePopUp from "../page_components/home/subscribe-pop-up"
import Home from "../page_components/home/home.js"
// import WhyAccesSOS from "../page_components/home/why-accessos.js"
// import GetAccessos from "../page_components/home/get-accessos.js"
// import OurStory from "../page_components/home/our-story.js"
import Supporters from "../page_components/home/supporters.js"
// import AsSeenIn from "../page_components/home/as-seen-in.js"
import GetInvolved from "../page_components/home/get-involved.js"

import "./index.css"

// import SEO from "../components/seo"

class IndexPage extends React.Component {
  constructor() {
    super()
    this.state = {
      popup: false,
      sidebarToggle: false,
    }
  }

  toggler = () => {
    this.setState({ sidebarToggle: !this.state.sidebarToggle })
    console.log(this.state.sidebarToggle)
  }

  componentDidMount() {
    if (typeof window.gtag !== "undefined")
      window.gtag("event", "conversion", {
        send_to: "AW-10933926847/VKyoCPbfscYDEL_32d0o",
      })
  }

  openPopUp = () =>
    this.setState({
      popup: true,
    })

  closePopUp = () =>
    this.setState({
      popup: false,
    })

  //sideState={this.state.sidebarToggle}

  render() {
    return (
      <Layout setSideState={this.toggler}>
        <div>
          {/* <SEO title="Home" /> */}
          <div>
            {this.state.popup && (
              <div className="popup">
                <SubscribePopUp closePopUp={this.closePopUp} />
              </div>
            )}
            <div id="home" className="scroll"> <Home/> </div>
            <div id="supporters" className="scroll"> <Supporters /> </div>
            <div id="get-involved" className="scroll"> <GetInvolved /> </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage